.filters {
    /*border:red solid 1px;*/
    display: inline-block;
    /*vertical-align: super;*/
}

.filter_front {
    /*border: red solid 1px;*/
    display: inline-block;
    height: 30px;
    /*vertical-align: super;*/
}

.filters_choices {
    display: inline-block;
    width: 120px;
    border: none;
    /*vertical-align: super;*/
}