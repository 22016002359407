.input {
    border: none;
    /*border: red solid 1px;*/
    width: 100%;
    height: 100%;
    /*margin-bottom: ;*/
}

.fields-img-path-button {
    /*border: red solid 1px;*/
    text-align: center;
    margin-top: 5px;
}
.fields-img-path-button-one {
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.fields-img-path-img-border {
    border: rgb(200, 200, 200) solid 1px;
}

.fields-img-path-middle {
    text-align: center;
}