.logo {
    display: inline-block;
    height: 50%;
    color: white;
    font-size: 150%;
}

.login-out {
    display: inline-block;
    float: right;
}
.login-cover{
    display: inline-block;
    float: right;
    color: #c8c8c8;
}

.username-info {
    color: white;
    /*border: #44ff24 solid 1px;*/
    display: inline-block;
    float: right;
    margin-right: 5px;
}
.login-out-button{
    color: #ffffff;
    /*border: #44ff24 solid 1px;*/
    display: inline-block;
    float: right;
    margin-right: 5px;
}



