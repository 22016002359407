.detail-title {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
}

.img-div {
    border: #c8c8c8 solid 1px;
    margin-top: 10px;
}

