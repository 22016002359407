.file-input {
    display: none;
}

.file-button {
    /*border: #73ff4e solid 1px;*/
    width: 100%;
    height: 190%;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 3px;
    border: none;
    /*display: inline-block;*/
}

.file-button:focus,
.file-button:active:focus,
.file-button.active:focus,
.file-button.focus,
.file-button:active.focus,
.file-button.active.focus {
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

.file-button:active {
    font-size: 120%;
}

.file-info-line {
    margin-bottom: 3px;
    margin-left: 3px;
}

.file-infos {
    margin-top: 10px;
}

.file-info-left {
    display: inline-block;
    font-weight: 600;
}
