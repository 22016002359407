.input {
    border: none;
    /*border: red solid 1px;*/
    width: 100%;
    height: 100%;
    /*margin-bottom: ;*/
}

.button-change {
    width: 100%
}

.button {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

.fields-img-path-button {
    /*border: red solid 1px;*/
    text-align: center;
    margin-top: 5px;
}

.fields-img-path-button-one {
    margin-left: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.img-head-fields-img-path-img-border {
    border: rgb(200, 200, 200) solid 1px;
    width: 200px;
    height: 200px;
    margin-left: 136px;
}

.fields-img-path-middle {
    text-align: center;
}


.border-image {
    border: red solid 1px;
    width: 472px;
    height: 472px;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    /*position: absolute;*/
}

.border-image-2 {
    /*border: red solid 1px;*/
    width: 472px;
    height: 472px;
    /*background-color: #01fd95;*/
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    /*position: absolute;*/
    margin-top: -472px;
}

.border-image-in {
    /*border: red solid 1px;*/
    width: 200px;
    height: 200px;
    margin-left: 136px;
    /*margin-top: 136px;*/
    padding: 1px;
    /*position: absolute;*/
}

.preview-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
}