.powerTable {
    border: #c8c8c8 solid 1px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: auto;
    box-shadow: 2px 2px 10px #909090;
}


.subTable {
}

.powerTableHead {
    background-color: #ffffff;
    border-radius: 5px;
    overflow: auto;
    border: rgba(130, 50, 21, 0.16) solid 1px;
    /*vertical-align: middle;*/
}

.rootTableBack {
    background-color: #ffffff;
    overflow: auto;
    margin-bottom: 100px;
    box-shadow: 2px 2px 10px #909090;
}

.powerTableFooterButton {
    margin-right: 5px;
    display: inline-block;
}

.powerTableHeadButton {
    margin: 5px;
    display: inline-block;
}