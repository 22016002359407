.title {
    /*border: red solid 1px;*/
    /*margin-left: 10px;*/
    text-align: center;
    font-size: 120%;
}

.choice-line {
    margin-left: 5px;
    margin-bottom: 3px;
}

.choice-input {
    /*margin-right: 4px;*/
}