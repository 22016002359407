.help-array-title {
    font-weight: bold;
    margin-right: 5px;
    text-align: center;
}

.help-array-line {
    margin-left: 5px;
    margin-bottom: 5px;
}
