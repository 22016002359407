.out {
    /*height: 40px;*/
    /*width: 60%;*/
    /*border: red solid 1px;*/
}

.front_text_out {
    border: #b4b4b4 solid 1px;
    display: inline-block;
    /*width: 18%;*/
    height: 100%;
    font-size: 100%;
    text-align: center;
    float: left;
}

.front_text_in {
    font-size: 130%;
    margin-top:1px;
    margin-left:10px;
    margin-right:10px;
}

.content {
    /*border: #b4b4b4 solid 1px;*/
    display: inline-block;
    width: 50%;
    height: 100%;

}

.required {
    display: inline-block;
    color: red;
}
